<template>

  <div>
    <div class="box">
      <div style="position:absolute;width: 45px;height:45px;" @click="slip()">
        <img loading="lazy" src="@/assets/slipleft.png"></div>
      <div class="ViewTitle" style="justify-content: center">浏览记录 </div>
      <div class="box">
        <div style="display: flex;background-color: #f7f7f7">
          <div style="background-color: white">
            <div class="ViewTitle1" >
              <div style="display: flex">
                <div class="title">职位:</div>
                <div class="titleInput">
                  <el-input
                      v-model="positionName"
                      max-length="20"
                      @change="getData"
                      placeholder="输入职位名称进行搜索"
                  ></el-input>
                </div>
              </div>
              <div>
                <el-button style="background-color: #2168DB;color: white;height:fit-content;width: fit-content;padding: 10px;font-size: 14px;margin:0 0 auto auto" @click="getData" icon="el-icon-search">搜索</el-button>
              </div>

            </div>
            <div class="messageList">
              <div class="middle" v-if="messageList.length!==0">
                <li v-for="(lists,index) in messageList" :key="index">
                  <div class="listTotle" >

                    <div class="detail" @click="personal(lists)">
                      <div>
                        <div style="display: flex">
                          <div class="positionName">
                            {{ lists.positionName }}</div
                          >
                          <div class="salaryName">
                            {{ lists.positionSalary }}</div
                          >
                        </div>
                        <div style="margin-top: 10px">
                      <span class="positionName" style="color: gray;font-size: 12px" >
                     {{ lists.companyName }}</span
                      >

                        </div>
                        <div style="display: flex">
                          <div class="jobStyle">
                            <span class="jobStyle1">{{ lists.positionAddress}}</span>
                            <span class="jobStyle2">{{lists.positionTime  }}</span>
                            <span class="jobStyle2">{{lists.positionEducation}}</span>
                          </div>
                        </div>

                      </div>
                      <div class="comButton">
                        <div class="comLogo">
                          <img :src="baseURL + lists.companyAvatar" alt="" />
                        </div>
                        <div class="companyName">
                          <div class="name1">{{ lists.companyName }}</div>
                          <div style="display: flex;margin-top: 15px">
                            <div class="jobStyle1" style="display: flex">
                              <div  v-for="(item, index) in lists.companyIndustry" :key="item.id">
                                <span>{{ item }}</span>
                                <span v-if="index < lists.companyIndustry.length - 1">、</span>
                              </div>
                            </div>

                            <div class="jobStyle2">{{ lists.companyFinancing }}</div>
                            <div class="jobStyle2">{{ lists.companyScale }}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="interview">
                      <div style="display: flex">
                        <div class="interId">
                          <el-avatar :size="30" :src="baseURL +lists.interviewAvatar"></el-avatar>
                        </div>
                        <div class="name">
                          {{lists.interviewName}}
                        </div>
                        <div class="name">
                          {{lists.interviewDuties}}
                        </div>
                      </div>
                      <div @click="deleteScan(lists)">
                        <i style="color: #2167d9" class="el-icon-delete-solid"></i>
                      </div>
                    </div>
                  </div>
                </li>
                <div v-if="messageList.length !== 0" class="pagination-container">
                  <el-pagination
                      v-model="page"
                      :current-page="page"
                      :page-size="size"
                      :page-sizes="[5, 8, 10, 15]"
                      :total="total"
                      background
                      layout=" prev, pager, next"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="noData" style="width: 100vw;font-size: 14px"  v-else>
                <img src="@/assets/none.png" alt="" />
                暂无数据
              </div>
            </div>

          </div>

        </div>
      </div>
      <bottomMobile></bottomMobile>
    </div>
  </div>

</template>


<script>

import {shareUrl} from "@/utils/vxshare";
import bottomMobile from "@/components/bottomMobile.vue";
// import PersonalLittle from "@/components/PersonalLittle.vue";
export default {

  name: "",

  props: [],

  components: {bottomMobile},
  data() {

    return {
      positionName:"",
      positionAddress:'',
      messageList: [],
      page: 1,
      size:8,
      baseURL: this.$utils.baseURL,
      total:"",
      positionInterviewId:'',
      userId:localStorage.getItem("userId"),
      // userId:2,
      status:'',
      languageSelect:'',
      interView:[],
    };

  },

  methods: {
    slip(){
      this.$router.go(-1)
    },
    statusChange(item){
      this.status=item
      this.size=8
      this.page=1
      this.getData()
    },
    // 详情
    personal(list){
      console.log(list)
      this.$router.push({
        path: "/positionDetail",
        query: {
          positionId:list.positionId,
        },
      });
      // window.location.reload();

    },
    deleteScan(list){
      this.$axios.delete(`/system/brow/${list.browId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        params: {
          userId: this.userId,
          positionId: this.positionId,
        },
      })
          .then((res) => {
            console.log(res.data);
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getData()
          })
          .catch(error => {
            console.log(error);
          });

    },
    handleSizeChange(val) {
      this.size = val;
      window.scrollTo(0, 0);
      // console.log(`每页 ${val} 条`+this.size)
      // console.log('gengxin')

      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      window.scrollTo(0, 0);
      // this.handleScroll();
      // console.log(`当前页: ${val}`+this.page)
      this.$forceUpdate(); //强制重新渲染分页器组件
      this.getData();
    },
    getData(){
      this.$axios
          .get("/system/brow/list", {
            params: {
              userId:this.userId,
              pageNum:this.page,
              pageSize:this.size,
              positionAddress:this.positionAddress,
              positionName:this.positionName,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then(async (res) => {
            this.messageList = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
            this.total = res.data.total
            // this.positionInterviewId=res.data.rows.positionInterviewId


            for (var i = 0; i < this.messageList.length; i++) {
              // console.log(this.messageList[i].companyIndustry);
              this.messageList[i].companyIndustry = JSON.parse(this.messageList[i].companyIndustry)
              if (this.messageList[i].positionTime !== null) {
                this.messageList[i].positionTime = await this.getTime(this.messageList[i].positionTime);
              }
              if (this.messageList[i].positionEducation !== null) {
                this.messageList[i].positionEducation = await this.getEducation(this.messageList[i].positionEducation);
              }


              if (this.messageList[i].sex === '0') {
                this.messageList[i].interviewName = this.messageList[i].interviewName[0] + "女士"
              } else {
                this.messageList[i].interviewName = this.messageList[i].interviewName[0] + "先生"
              }
            }

          })
          .catch((error) => {
            console.log(error);
          });
    },
    getTime(item) {
      return this.$axios
          .get("/system/time/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getEducation(item) {
      return this.$axios
          .get("/system/education/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getCategory(item) {
      return this.$axios
          .get("/system/position_category/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
  },

  created() {
    this.getData()

    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);

  },
  mounted() {
  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.noData img{
  width: 150px;
  margin:0 auto;
  text-align: center;
}
.noData, .notLogin{
  font-size: 14px;
}
.ViewTitle {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  .title{
    font-size: 14px;
  }
}
.jianli {
  margin:0 0 0 20px;
  width: 260px;
  height: 267px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
::v-deep .el-input__inner{
  border: 1px solid #DCDFE6 !important;
  height: 35px;
  margin-left: 5px;
}
::v-deep .el-message{
  max-width: 250px !important;
}
.middle{
  overflow:hidden;
  //margin:0 15px;
  width: 950px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  list-style: none;


  .pagination-container {
    position: sticky;
    margin-top: 20px;
    padding: 25px;
    bottom: 15px;
    width: 99%;
    text-align: center;
    ::v-deep .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
    ::v-deep .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;

    }
    ::v-deep .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
  ;
  }
  .listTotle {
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    margin-top: 10px;
    width: 100%;
    padding:0 15px;
    //border-radius: 5px;
    height: fit-content;
    //background:linear-gradient(to right, rgb(255, 248, 250) 50%, #eaeef8 100%);;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    //border:rgba(0, 0, 0, 0.05) 1px solid ;
    opacity: 1;

    .interview{
      display: flex;
      align-items: center;
      justify-content:space-between;
      //justify-content: center;
      height: 45px;
      //border-bottom: #d5d5d5 1px solid;
      //background:linear-gradient(to right, #fff9f7 50%, #f5f6fa 100%);;
      width: 100%;
      .name{
        margin: 6px 0 6px 8px ;
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .deliver{
        color: #bf0022;
        font-size: 13px;
      }
      .scan{
        font-size: 13px;
        color: #64a205;
      }
    }
    .interviewTime{
      display: flex;
      align-items: center;
      justify-content:space-between;
      //justify-content: center;
      height: 39px;
      //box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.05);

      margin-bottom: 10px;
      //background:linear-gradient(to right, #fff9f7 50%, #f5f6fa 100%);;
      width: 100%;
      .name{
        margin: 6px 0 6px 8px ;
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .deliver{
        color: #64a205;
        color: #bf0022;

      }
      .scan{
        font-size: 13px;
        color: #64a205;
      }
    }
    .detail {
      padding: 10px 0;
      display: flex;
      cursor: pointer;
      position: relative;

      .positionName {
        width: fit-content;
        height: 16px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        //font-weight: 600;
        color: #333333;
        line-height: 16px;
      }

      .positionName:hover {
        color: #bf0022;
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .salaryName {
        position: absolute;
        right:0;
        width: fit-content;
        height: 21px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        color: #bf0022;
        line-height: 22px;
      }

      .jobStyle {
        display: flex;
        margin-top: 10px;
        width: fit-content;

      }

      .jobStyle1 {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        border-radius: 6px;
        background-color:#f8f8f8;
        padding: 3px 10px;
      }

      .jobStyle1:hover {
        color: #bf0022;
        transform: scale(1.2);
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }

      .jobStyle2 {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        background-color:#f8f8f8;
        border-radius: 6px;
        padding: 3px 10px;
        margin-left: 12px;
      }

      .jobStyle2:hover {
        color: #bf0022;
        transform: scale(1.2);
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }


      .comButton {
        position: absolute;
        left:550px;
        display: flex;
        top: 15px;
        text-align: center;
        .comLogo {
          width: 35px;
          height: 35px;
          border: rgba(100, 98, 98, 0.1) 1px solid;
          border-radius: 4px;
          opacity: 1;
        }

        .comLogo:hover {
          transform: scale(1.1);
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }
        .companyName {
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          margin-left: 8px;

          .spanthis{
            width: 1px;
            margin: 3px 0 auto 16px;
            height: 16px;
            background-color: #e0dddd;
          }
          .name1 {
            width: 200px;
            height: 22px;

            font-size: 14px;
            font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            color: #050505;
            text-align: left;
            line-height: 22px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .name1:hover {
            color: #bf0022;
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }

          .name2 {
            height: fit-content;
            margin: 12px 15px 0 0;
            font-size: 13px;
            border: 9px;
            font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            padding: 3px 8px;
            background-color: #f8f8f8;
          }
          .name2:hover {
            color: #bf0022;
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }
        }
        .companyBut {
          margin-top: 10px;
          width: 180px;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          color: #bf0022;
          border-radius: 23px;
          opacity: 1;
          border: 1px solid #fdfdfd;
        }

        .companyBut:hover {
          /* 此处是鼠标悬浮时的样式 */
          color: rgba(100, 98, 98);
          background: #fdfdfd;
          border:  #416dfd  1px solid;
          /* 你可以添加其他的样式以突出悬浮状态 */
        }

        ::v-deep .el-button {
          padding: 5px 10px;
        }
      }
    }

  }
  .listTotle:hover {
    transform:translateY(-10px);

  }
}
.box {
  width: 100%;
  min-height: 78vh;
  background: #fff;
  .ViewTitle1 {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    .title{
      font-size: 14px;
    }
  }
  .messageList {
    display: flex;
    width:100vw;
    //padding-bottom: 48px;
    border-radius: 5px;
    min-height: 380px;
    justify-content: center;
    .list {
      padding: 0 30px;
      .item {
        display: flex;
        align-items: center;
        padding: 17px 22px;
        border: 1px solid #ebebeb;
        margin-top: 16px;
        .icon {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }
        .text {
          flex:1;
          .title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            cursor: pointer;
            max-width: 100%;
            width: fit-content;
            .step {
              .el-badge__content.is-dot {
                background-color: #bf0022 !important;
              }
            }
          }
          .flex {
            display: flex;
            font-size: 12px;
            align-items: center;
            margin-top: 6px;
            .time {
              color: #999999;
              margin-right: 11px;
            }
            span {
              color: #bf0022;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }

      .el-pagination {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}
</style>
